var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-2 mt-2",attrs:{"tile":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('CardTitle',{attrs:{"title":_vm.tableTitle,"icon":"pi-task-run"}},[_c('div',_vm._g({attrs:{"slot":"action"},slot:"action"},on),[_c('v-select',{staticClass:"time-interval-picker",attrs:{"items":_vm.dateFilters,"dense":"","solo":"","item-text":"name","item-value":"value","hide-details":"","flat":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{attrs:{"color":"black","x-small":""}},[_vm._v(" history ")])]},proxy:true}],null,true),model:{value:(_vm.selectedDateFilter),callback:function ($$v) {_vm.selectedDateFilter=$$v},expression:"selectedDateFilter"}})],1)])]}}])},[_c('span',[_vm._v(" Filter by when runs were last updated ")])]),_c('v-card-text',[_c('v-data-table',{staticClass:"truncate-table",class:{ 'fixed-table': _vm.$vuetify.breakpoint.smAndUp },attrs:{"footer-props":{
        showFirstLastPage: true,
        itemsPerPageOptions: [10, 15, 25, 50],
        firstIcon: 'first_page',
        lastIcon: 'last_page',
        prevIcon: 'keyboard_arrow_left',
        nextIcon: 'keyboard_arrow_right'
      },"headers":_vm.headers,"header-props":{ 'sort-icon': 'arrow_drop_up' },"items":_vm.task ? _vm.task.task_runs : null || [],"items-per-page":_vm.itemsPerPage,"loading":_vm.loading > 0,"must-sort":"","page":_vm.page,"server-items-length":_vm.taskRunsCount,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"calculate-widths":""},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:page":function($event){_vm.page=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.flow_run",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('router-link',{staticClass:"link",attrs:{"to":{ name: 'task-run', params: { id: item.id } }}},[_c('span',_vm._g({},on),[_vm._v(_vm._s(item.flow_run.name))])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.flow_run.name)+" - "+_vm._s(_vm.task.name)+" "),(item.map_index > -1)?_c('span',[_vm._v(" (Mapped Child "+_vm._s(item.map_index)+")")]):_vm._e()])])]}},{key:"item.start_time",fn:function(ref){
      var item = ref.item;
return [_c('truncate',{attrs:{"content":_vm.formatTime(item.start_time)}},[_vm._v(" "+_vm._s(_vm.formDate(item.start_time))+" ")])]}},{key:"item.end_time",fn:function(ref){
      var item = ref.item;
return [_c('truncate',{attrs:{"content":_vm.formatTime(item.end_time)}},[_vm._v(" "+_vm._s(_vm.formDate(item.end_time))+" ")])]}},{key:"item.duration",fn:function(ref){
      var item = ref.item;
return [(item.start_time)?_c('DurationSpan',{attrs:{"start-time":item.start_time,"end-time":item.end_time}}):_c('span',[_vm._v("...")])]}},{key:"item.state",fn:function(ref){
      var item = ref.item;
return [_c('truncate',{attrs:{"content":item.state}},[_c('v-icon',{staticClass:"mr-1 pointer",attrs:{"small":"","color":item.state}},[_vm._v(" brightness_1 ")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }